import React from 'react'
import Link from 'gatsby-link'
import PortfolioCard from '../components/PortfolioCard';
import Section from '../components/Section';
import Wave from '../components/Wave';
import staticdata from '../../staticdata.json'
import Cell from '../components/Cell';
import styled from 'styled-components'
import Navbar from '../components/Navbar/Navbar.js'
import Footer from '../components/footer'
import Testimonial from '../components/Testimonial'
import './services.css'
import Head from '../components/head'
import Card from '../components/Card';
import Sectionnew from '../components/Sectionnew';



const Brunchwrapper = styled.div`
max-width: 1000px;
  margin: 50px auto;
  display: grid;
  grid-template-columns: 120px 250px 200px 300px;
  grid-column-gap: 20px;
  padding: 50px 20px;
  background:#f9f9f9;

@media (max-width: 800px) {
  grid-template-columns: repeat(1, 1fr);

}
`

const Brunch = styled.div`
width:100%;
position:relative;

p{
  font-size:16px;
  padding:0;
  margin:0;
}

h2{
  font-size:20px;
  padding:0;
  margin:0;
}

span{
 font-weight:400;
}

h6{
  font-size:15px;
  padding:0;
  margin:0;
  font-style:oblique;
  font-weight:500;
  padding-bottom:10px;
}
`



const SectionCaption = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #94A4BA;
  text-align: center;
`
const Button = styled.button`
    background: linear-gradient(102.24deg, #ffcc00 0%, #ffcc00 100%);
    box-shadow: 0px 10px 20px rgba(101, 41, 255, 0.15);
    border-radius: 30px;
    color: white;
    border: none;
    padding: 16px 60px;
    
    
    font-weight: 600;
    font-size: 18px;
    justify-self: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:hover {
        box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
        transform: translateY(-3px);
    }
`

const SectionCellGroup = styled.div`
  max-width: 400px;
  margin: 0 auto 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  padding: 0 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Party = () => (
  <div>
      <Head title="Professional Catering for Kids Party" keywords="kids party, catering, professional catering" description="Professional catering experts for kids party with quality food" image="/images/ecommerce-page.jpg"/>

<Navbar />
<div className="HeroPortfolio">
      <div className="HeroPortfolioGroup">
        <h1>KIDS PARTY</h1>
        <p>We have 2 fantastic menus to choose from when it comes to your Kid's party.

</p>
   
    <Button><Link to = "/contact">Book Now</Link></Button>

        <Wave />
      </div>
    </div>






    <div className="homenn">
    <p>All the food is homemade in the Tima’s kitchen using fresh, locally sourced ingredients (vegetarian and vegan options upon request)</p>
    </div>


<Brunchwrapper>
<Brunch><img  src='/images/brunch.svg'  /></Brunch>
<Brunch><h2><span>Menu </span> A</h2>
<h6>£7 per person </h6>
</Brunch>
<Brunch>
<p>Mini hot dog & Mini cheeseburger
<b> Or </b>
Vegetable mince pie & Mini Margherita pizza</p>
<p><br/>Zoodles</p>
<p><br/> Selection of seasonal fruits
</p>
<p>Madeleine & 1 Mini cheesecake
<b> Or </b> 2 Chocolate cake pop


</p>

</Brunch>

<Brunch><h6>Let's create a magical party together!</h6>

    <Link to="/contact"><Button>Book Now</Button></Link>

</Brunch>
</Brunchwrapper>

  



<Brunchwrapper>
<Brunch><img  src='/images/brunch.svg'  /></Brunch>
<Brunch><h2><span>Menu </span> B</h2>
<h6>£9 per person </h6>
</Brunch>
<Brunch>
<p>Mini hot dog & Mini cheeseburger

<b> Or </b>
Mini chicken and goat cheese muffin & Tima’S Tuna salad
</p>
<p><br/>Chicken goujons and potatoes
</p>
<p><br/> Crudities and Hummus dip

</p>
<p>2 Mini cupcakes

<b> Or </b> Nutella pancakes



</p>

</Brunch>

<Brunch><h6>Let's create a magical party together!</h6>

    <Link to="/contact"><Button>Book Now</Button></Link>

</Brunch>
</Brunchwrapper>






   
    <Section
      image='/images/newbg.png'
      logo=''
      title="Interested?"
      text="Want Tima's Flavours to handle your professional catering services? Please provide as much details as you can on our contact form and we’ll get back to you within 48 hours"
      button="Get In Touch"
     />
      

      <div className="Cards">
      <h4>CUSTOMER STORIES</h4>
      <h2>5 STARS ON GOOGLE</h2></div>

<div className="Testimonial">
      
     
<Testimonial 
          title="We Hired Tima for our corporate event and the food was absolutely amazing as was the service! Highly recommended!"
          text=""
          author="Joshua"
          image='/images/josh.jpg' /> 
         
        <Testimonial 
          title="I absolutely recommend Tima to anyone who has an event that requires catering. I send my children to her cooking classes too!"
          text=""
          author="Elizabeth"
          image='/images/eli.jpg' />
             
    </div>
    <div className="Cardb">
    <Button>More Stories</Button>
</div>

     <Footer/>
    
 
     

  </div>
)

export default Party
